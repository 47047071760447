export function copy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

export function splitArray2Parts(arr, size) {
  const result = []
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }
  return result
}

export function camelCase(name) {
  return name.substr(0, 1).toLowerCase() + name.substr(1)
}

export class UrlBuilder {
  constructor(url) {
    this.url = url
    this.paramList = []
  }

  append(name, value) {
    if (isNotEmpty(value)) {
      this.paramList.push({ name: name, value: value })
    }
    return this
  }

  build() {
    this.paramList.forEach((e, i, a) => {
      if (i === 0) {
        this.url += '?'
      } else {
        this.url += '&'
      }
      this.url += e.name + '=' + e.value
    })
    return this.url
  }
}

export function isNotEmpty(val) {
  return val !== undefined && val !== null && val !== ''
}

export function isEmpty(val) {
  return val === undefined || val === null || val === ''
}

export function prepareUrl(url) {
  return new UrlBuilder(url)
}

export function debounce(fn, delay = 500) {
  let timer
  return (e) => {
    e.persist()
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn(e)
    }, delay)
  }
}

export function throttle(fn, delay, atleast) {
  let timeout = null,
    startTime = new Date()
  return function () {
    let curTime = new Date()
    clearTimeout(timeout)
    if (curTime - startTime >= atleast) {
      fn()
      startTime = curTime
    } else {
      timeout = setTimeout(fn, delay)
    }
  }
}

export function clickUrl(url) {
  const httpLink = document.createElement('a')
  httpLink.href = url
  httpLink.target = '_blank'
  httpLink.click()
}

export function escape2Html(str) {
  var temp = document.createElement('div')
  temp.innerHTML = str
  var output = temp.innerText || temp.textContent
  temp = null
  return output
}

export function formDataToObject(formData) {
  const object = {}
  formData.forEach((value, key) => {
    // 如果表单中有多个相同名称的字段（例如，复选框），则这些值将存储在数组中
    if (object[key]) {
      if (Array.isArray(object[key])) {
        object[key].push(value)
      } else {
        object[key] = [object[key], value]
      }
    } else {
      object[key] = value
    }
  })
  return object
}
